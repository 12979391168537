.hideelement {
  display: none !important;
}

.react-calendar__navigation__label__labelText {
  font-weight: bold !important;
}
.react-calendar {
  border-radius: 10px;
  border-color: lightgray !important;
  overflow: hidden;
}
.react-calendar__tile--active {
  background: #ef3c3c !important;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ef3c3c !important;
}

.react-calendar__tile--now {
  /*background: transparent !important;*/
  color: black !important;
}

.react-calendar__tile--now:enabled:hover {
  background: lightgray !important;
}

.react-calendar__tile--now:enabled:focus {
  background: #ef3c3c !important;
  color: white !important;
}
.react-calendar__month-view__days {
  overflow: hidden;
}
.react-calendar__month-view__days > .react-calendar__tile {
  border-style: solid !important;
  border-width: 1px;
  border-color: lightgray;
}

.booking-container {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  margin: 10px;
  display: flex;
  flex-direction: column;
  width: 340px;
  max-width: 100%;
  position: absolute;
  top: -120px;
}
.time-container {
  border-bottom-style: solid;
  border-color: lightgray;
  border-width: 1px;
  padding-bottom: 20px;
}

.times-available {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  grid-gap: 18px;
  gap: 18px;
  flex-wrap: wrap;
  justify-content: center;
}

.times-available > div {
  text-align: center;
  font-weight: bold;
  color: #002c4c;
  cursor: pointer;
}

.times-available input[type="radio"]:checked + label {
  color: #ef3c3c;
}

.pricing-container header {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: #002c4c;
  margin-bottom: 20px;
  padding-top: 20px;
}
.pricing-container header span {
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}

.guest-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.guest-container .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  color: #002c4c;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: lightgray;
  padding-bottom: 10px;
}
.guest-container .row:last-child {
  border: none;
}
.guest-container .row > * {
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.guest-name {
  justify-content: flex-start !important;
}
.guest-price {
  justify-content: flex-end !important;
}

.guest-container .guest-price {
  font-size: 10px;
}
.guest-controls {
  justify-content: space-evenly !important;
}
.guest-controls button {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  cursor: pointer;
  border-width: 1px;
}

.booking-container footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 20px;
  padding-bottom: 20px;
  color: #002c4c;
  font-weight: bold;
}
.booking-container footer button {
  width: 100%;
  border-radius: 20px;
  height: 40px;
  font-size: 18px;
  font-weight: bold;
  background-color: #ef3c3c;
  color: white;
  border-style: none;
  cursor: pointer;
}
.booking-modal * {
  color: #002c4c;
}
.booking-modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /*bottom: 0;*/
  /*min-height: 100vh;*/
  width: 100%;
  z-index: 99999999;
  background-color: white;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;
}

.row-creditcard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}
.row-creditcard::after {
  content: var(--creditType);
  background-color: blue;
  display: block;
  position: absolute;
  right: 20px;
  top: 77px;
  font-size: 20px;
  color: lightgray;
  font-weight: bold;
}

.creditcardtype {
  display: block;
  position: absolute;
  right: 20px;
  top: 77px;
  font-size: 20px;
  color: lightgray;
  font-weight: bold;
}
.row-creditcard > input {
  width: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 2;
  background: transparent;
}
.row-creditcard input {
  font-size: 20px;
  border-width: 1px;
  padding: 10px;
  border-color: lightgray;
}
.creditcard-security {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.creditcard-security input {
  border-top-style: none;
}

.creditcard-security .date {
  border-bottom-left-radius: 10px;
  width: 40%;
}
.creditcard-security .cvv {
  border-right-style: none;
  border-left-style: none;
  width: 30%;
}
.creditcard-security .zip {
  border-bottom-right-radius: 10px;
  width: 30%;
}

.booking-modal > svg {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 30px;
  cursor: pointer;
}
.customer-info {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: calc(100% / 2.5);
}

.customer-info .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
  gap: 12px;
  margin-top: 40px;
}

.customer-info > .row:last-of-type {
  flex-direction: column;
}
.customer-info > .row:last-of-type form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.customer-info > .row:last-of-type form > button {
  /*width: calc(100% - 30px);*/
  width: 100%;
  height: 40px;
}
.customer-info > .row:last-of-type form > .StripeElement {
  width: calc(100% - 30px);
}

.customer-info .row label {
  position: absolute;
  top: -24px;
}
.customer-info .column {
  position: relative;
  width: calc(100% / 2);
}

.customer-info .row input {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  width: 100%;
  font-size: 20px;
  padding-left: 10px;
}

.customer-info .column input {
  width: calc(100% - 16px);
}

.customer-info .row button {
  /*width: calc(100% - 20px);*/
  width: 100%;
  font-size: 20px;
  border-radius: 10px;
  color: white;
  background-color: #ef3c3c;
  border-style: none;
  cursor: pointer;
}

.booking-info {
  width: calc(100% / 2.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}
.info-box {
  border-style: none;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 10px;
  width: calc(100% / 1.5);
  background-color: white;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 10px 1px rgba(201, 201, 201, 0.88);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(201, 201, 201, 0.88);
  -moz-box-shadow: 0px 0px 10px 1px rgba(201, 201, 201, 0.88);
}
.info-box header {
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.info-box header img {
  position: absolute;
  border-radius: 50%;
  top: -33px;
  height: 80px;
  box-shadow: 0px 0px 10px 1px rgba(201, 201, 201, 0.88);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(201, 201, 201, 0.88);
  -moz-box-shadow: 0px 0px 10px 1px rgba(201, 201, 201, 0.88);
}
.booking-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.booking-body .row,
.info-box footer .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  justify-content: space-between;
  padding-right: 20px;
}
.booking-body .left {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
}

.info-box footer {
  padding-top: 40px;
  border-top-style: solid;
  border-width: 2px;
  border-color: lightgray;
}

.info-box footer > * {
  font-weight: bold;
  font-size: 20px;
}
.down {
  flex-direction: column !important;
  gap: 0 !important;
  margin-top: 0 !important;
}
.down h4 {
  margin-bottom: 0;
  padding-left: 10px;
}
.row-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.row-checkbox * {
  cursor: pointer;
}
.policy-container {  
  width: 100%;
  padding: 20px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.policy-container > span {
  text-align: center;
}
@media screen and (max-width: 1347px) {
  .booking-modal {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
  }
  .booking-modal > div {
    width: 100%;
  }
  .customer-info {
    width: calc(100% / 2) !important;
  }
  .info-box {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 984px) {
  .info-box {
    width: calc((100% / 2));
  }
  .customer-info > .row:first-of-type {
    flex-direction: column;
    gap: 40px;
  }
  .customer-info > .row:first-of-type .column {
    width: 100%;
  }
}

@media screen and (max-width: 663px) {
  .info-box {
    width: calc(100% - 40px);
  }
  .customer-info {
    width: calc(100% - 40px) !important;
  }
}
