.header-wrap {
  height: 120px;
  width: 100%;
  overflow: hidden;
  transition: all 200ms;
  z-index: 9999;
}

.overBanner {
  position: fixed;
  top: 0;
}

.noBanner {
  position: absolute;
  /*background-color: #383e45;*/
}

.header-wrap > .top {
  background-color: #383e45;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-wrap > .top > button {
  color: #ffffff;
  background-color: #35979c;
  border-color: #35979c;
  padding: 0.0625rem -147.6875rem;
  margin-right: 10px;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
  transition: all 200ms;
}
.header-wrap > .bottom {
  height: 80px;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}

.bottom > .bottom-backcolor {
  background-color: #383e45;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
  transition: all 0.8s;
}

.bottom > img {
  height: 100%;
  cursor: pointer;
}

.bottom > ul {
  list-style-type: none;
  display: flex;
  color: white;
  padding-right: 20px;
}
.bottom > ul > li {
  padding: 15px;
}
.search {
  color: white;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.bars {
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: none;
}

.colorheader {
  background-color: #383e45;
}

#tourlist li {
  cursor: pointer;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: underline;
  font-weight: bold;
}

@media screen and (min-width: 826px) {
  .header-wrap {
    height: 120px !important;
  }
}

@media screen and (max-width: 825px) {
  .bars {
    margin-left: 20px;
    display: block;
  }

  .bottom > ul {
    display: block;

    position: absolute;
    top: 64px;
    padding: 0;
    width: 100%;
  }

  .bottom > ul > li {
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
  }
  .backimage {
    top: 0;
  }
}
