.banner .search-container {
  width: 100%;
}
.search-container {
  display: flex;
  z-index: 200;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 50px;
}
.search-inner-container {
  width: calc(100% - 40px);
  max-width: 600px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
.search-container input {
  width: calc(100% - 14px);
  max-width: 600px;
  font-size: 25px;
  padding-left: 10px;
  border-radius: 10px;
  border-width: 1px;
}
.search-container ul {
  position: absolute;
  top: 25px;
  background: white;
  max-width: 585px;
  width: calc(100% - 40px);
  /*border-radius: 10px;*/
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  color: black !important;
  max-height: 600px;
  overflow: auto;
  box-shadow: 0px 8px 17px 1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 8px 17px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 8px 17px 1px rgba(0, 0, 0, 0.75);
}
.search-results{
  list-style-type: none;
}
.search-results > li{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-results .list-icon{
  position: absolute;
  left: -30px;
}

.search-container li {
  padding-top: 10px;
  cursor: pointer;
  color: rgb(100, 100, 100);
}

.search-full-page {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #002c4c;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow-y: auto;
  padding-top: 120px;
}
.search-full-page .search-container {
  margin-top: 0;
}
.search-full-page .search-container ul {
  max-height: calc(100vh - 300px);
  /*min-height: 100px;*/
  overflow: auto;
}

.search-full-page input {
  border-radius: 0;
}
.search-full-page h2 {
  color: white;
  text-align: center;
}

.search-full-page .search-close {
  color: white;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  min-width: 100px;
}

.search-close svg {
  font-size: 19px;
}

.search-close:hover {
  background-color: red;
  cursor: pointer;
  border-radius: 10px;
  font-weight: bold;
}

.search-inner-container input:not(:focus) ~ ul {
  transition: all 200ms;
  opacity: 0;
  visibility: hidden;
}

/*---------------------------------------------search animation*/
.lds-ring {
  display: inline-block;
  position: absolute;
  width: 30px;
  height: 30px;

  right: 10px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 4px;
  border: 8px solid #252525;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #d3d3d3 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*search animation*/
