.slider-wrapper {
  margin: 1rem;
  position: relative;
  overflow: hidden;
  width: 50%;
}

.slides-container {
  min-height: calc(50vh);
  width: 100%;
  display: flex;
  overflow: scroll;
  scroll-behavior: smooth;
  list-style: none;
  margin: 0;
  padding: 0;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.slides-container::-webkit-scrollbar {
  display: none;
}

.slide-arrow {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 4rem;
  background-color: white;
  border: none;
  width: 2rem;
  font-size: 3rem;
  padding: 0;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 100ms;
}

.slide-arrow:hover,
.slide-arrow:focus {
  opacity: 1;
}

#slide-arrow-prev {
  left: 0;
  padding-left: 0.25rem;
  border-radius: 0 2rem 2rem 0;
}

#slide-arrow-next {
  right: 0;
  padding-left: 0.75rem;
  border-radius: 2rem 0 0 2rem;
}

.slide {
  width: 100%;
  height: 100%;
  flex: 1 0 100%;
}

.slide:nth-child(1) {
  background-color: #49b293;
}

.slide:nth-child(2) {
  background-color: #b03532;
}

.slide:nth-child(3) {
  background-color: #6a478f;
  margin: 0;
}

.slide:nth-child(4) {
  background-color: #da6f2b;
}

@media screen and (max-width: 900px) {
    .slider-wrapper {
      width: 70%;
    }
  }

@media screen and (max-width: 620px) {
  .slider-wrapper {
    width: 100%;
  }
}
