.index-alltours-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.banner {
  min-height: 50vh;
  width: 100%;
  position: relative;
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.backimage {
  background-image: url(../images/doorman.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: -145.35px;
  left: 0;
  right: 0;
  bottom: 0;
}
.dark-filter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 52%) !important;
  z-index: 2;
}
.title-section {
  z-index: 2;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  text-align: center;
}
.title {
  font-size: 3em;
  font-weight: bold;
}
.undertitle {
  padding-top: 20px;
  font-size: 25px;
}

.feature-section {
  padding-top: 20px;
  text-align: center;
}

.feature-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.feature-section-title {
  font-size: 30px;
}
.details {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.details-wrap {
  display: flex;
  flex-wrap: wrap;
}

.details-card {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
}
.details-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.details-title {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right {
  text-align: right;
}

.left {
  flex-direction: row-reverse;
  text-align: left;
}

.details-title > span {
  font-size: 30px;
  margin-right: 16px;
  margin-left: 16px;
}

.details-title > svg {
  color: #685563;
  font-size: 30px;
}

.details-description {
  max-width: 400px;
}

.details-divider {
  min-width: 100px;
  display: flex;
  justify-content: space-between;
}
.divider {
  width: 100%;
}
.line {
  border-left-style: solid;
  border-width: 1px;
  border-color: #dbdbdb;
}

.carousel-section {
  background-color: lightgray;
  padding: 20px;

  margin-bottom: 20px;
  /*
  display: flex;
  justify-content: center;
  align-items: center;
  */
}

#snippet {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 967px) {
  .feature-wrap {
    flex-direction: column;
    align-items: center;
  }
  .feature-card {
    width: calc(100% - 50px) !important;
    /*height: 50vh !important;*/
  }
}

@media screen and (max-height: 788px) {
  .banner {
    padding-top: calc(120px);
  }
  .title {
    font-size: 2em;
  }
  .undertitle {
    font-size: 20px;
  }
}

@media screen and (max-width: 630px) {
  .title {
    font-size: 2em;
  }
  .undertitle {
    font-size: 20px;
  }
  .banner {
    padding-top: 100px;
  }
}

@media screen and (max-width: 1035px) {
  .details-wrap {
    justify-content: flex-start;
    flex-direction: column;
  }

  .details-divider {
    display: none;
  }
  .right {
    flex-direction: row-reverse;
    text-align: left;
  }

  .details-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .details-title > span {
    font-size: 25px;
  }

  .details-card {
    justify-content: space-around;
  }
  .details-card > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
