.gold-rating {
  color: gold;
  margin: 2px;
}
.white-rating {
  color: white;
  margin: 2px;
}
.flash-orange{
  color: orange !important;
}
.rating-container {
  display: flex;
  flex-direction: column;
  border-style: solid;
  justify-content: center;
  border-width: 1px;
  border-color: lightgray;
  max-width: 500px;
  margin: 10px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
}
.rating-container header {
  display: flex;
  justify-content: space-between;
  background-color: #002c4c;
  margin-bottom: 10px;
  padding: 10px;
}

.rating-container header .close {
  background-color: rgb(199, 13, 13);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.rating-container header .close:hover {
  background-color: rgb(215, 16, 16);
  font-size: 15px;
}

.rating-section {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
}

.rating-section textarea {
  min-height: 100px;
}

.rating-container footer button {
}
.rating-container footer {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.rating-container footer button {
  background: #002c4c;
  background-image: -webkit-linear-gradient(top, #002c4c, #024473);
  background-image: -moz-linear-gradient(top, #002c4c, #024473);
  background-image: -ms-linear-gradient(top, #002c4c, #024473);
  background-image: -o-linear-gradient(top, #002c4c, #024473);
  background-image: linear-gradient(to bottom, #002c4c, #024473);
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
}

.rating-container footer button:hover {
  background: #0065a3;
  background-image: -webkit-linear-gradient(top, #0065a3, #002c4c);
  background-image: -moz-linear-gradient(top, #0065a3, #002c4c);
  background-image: -ms-linear-gradient(top, #0065a3, #002c4c);
  background-image: -o-linear-gradient(top, #0065a3, #002c4c);
  background-image: linear-gradient(to bottom, #0065a3, #002c4c);
  text-decoration: none;
}

.rating-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.874);
  z-index: 9999999;
}

.customer-review-container {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 716px;
}
.customer-review-container h2 {
  margin-left: 20px;
}
.customer-review-flex {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
}
.customer-review {
  width: calc(100% - 60px);
  max-width: 500px;
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  position: relative;
}

.customer-review header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
}

.customer-review header svg {
  color: gold;
  font-size: 20px;
}

.customer-review .description {
  text-align: justify;
  padding-top: 10px;
}

.customer-review .expand {
  position: absolute;
  bottom: 0;
  background-color: #e2e2e2;
  width: calc(100% - 20px);
  padding-top: 20px;
  text-align: right;
  right: 20px;
  left: 0;
  display: block;
  padding-right: 20px;
  font-weight: bold;
  cursor: pointer;
}
.review-error {
  background: red;
  color: white;
  border-radius: 10px;
  padding: 0 10px 0 10px;
  display: none;
}
