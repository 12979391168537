.alltours-container {
  min-height: calc(100vh - 310px);
  display: flex;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.filter-icons {
  display: flex;
  gap: 12px;
  align-items: center;
  width: calc(100% - 20px);
  justify-content: center;
}
.filter-icons > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  cursor: pointer;
  z-index: 2;
}
.filter-icons svg {
  font-size: 30px;
}

.search-container-search {
  display: flex;
  z-index: 999999;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 10px 20px;
  width: calc(100% - 40px);
}

.search-container-search input {
  width: calc(100% - 40px);
  max-width: 600px;
  font-size: 25px;
  padding-left: 10px;
  border-radius: 10px;
  border-width: 1px;
}

.tour-specs-search {
  height: 270px;
  background-color: #002c4c;
  position: relative;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}
.filter-clear {
  color: white;
  cursor: pointer;
  width: 20%;
  z-index: 99999;
}
@media screen and (max-width: 825px) {
  .tour-specs-search {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 40px;
    padding-left: 0;
    padding-right: 0;
    gap: 20px;
  }

  .filter-icons {
    padding-left: 10px;
    padding-right: 10px;
    gap: 0px;
    justify-content: space-evenly;
  }
  .filter-clear {
    width: 100%;
    text-align: center;
  }
}
