.signpadcontainer {
  position: relative;
  height: 200px;

  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  padding: 20px;
}
.signpad {
  width: 100%;
  height: 100%;
  background-color: rgb(231, 231, 231);
}
.clear {
  position: absolute;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}
