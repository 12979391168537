.page-footer {
  width: 100%;
}
.footer-top {
  height: 300px;

  background-color: #383e45;
  color: white;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 20px;
}
.footer-top > span {
  font-size: 26px;
}
.footer-top > ul {
  list-style-type: none;
  padding: 0;
  font-size: 16px;
}

.footer-top > ul > li {
  display: flex;
  align-items: center;
  padding: 10px;
}
.icon-space {
  margin-right: 10px;
}
.footer-bottom {
  height: 55px;

  background-color: #2d3237;
  color: rgb(225, 223, 223);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}
@media screen and (max-width: 1000px) {
    .footer-top > ul{
        font-size: 14px;
      }
}
@media screen and (min-width: 1000px) {
  .footer-top {
    padding-left: 100px;
  }

  .footer-bottom {
    padding-left: 100px;
    justify-content: flex-start;
  }
}
