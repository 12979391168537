.snapscroll-container > * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.snapscroll-container {
  box-sizing: border-box;

  display: flex;
  overflow-x: scroll;
  padding: 24px;
  width: calc(100vw - 100px);

  scroll-snap-type: x mandatory;
  scroll-padding: 24px;
  border-radius: 8px;
  gap: 12px;
  margin: 2rem auto;

  scrollbar-width: auto;
  scrollbar-color: #cfcdd0 #ffffff;
}
.snapscroll-container::-webkit-scrollbar {
  width: 15px;
}

.snapscroll-container::-webkit-scrollbar-track {
  background: #ffffff;
}

.snapscroll-container::-webkit-scrollbar-thumb {
  background-color: #cfcdd0;
  border-radius: 10px;
  border: 6px solid #ffffff;
  
}

.snapscroll-item {
  flex: 0 0 100%;
  max-width: 300px;
  scroll-snap-align: start;
}

.snapscroll-item > div:hover {
  box-shadow: 8px 8px 5px -4px rgba(176, 176, 176, 0.75);
  -webkit-box-shadow: 8px 8px 5px -4px rgba(176, 176, 176, 0.75);
  -moz-box-shadow: 8px 8px 5px -4px rgba(176, 176, 176, 0.75);
  transform: scale(0.9);
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .snapscroll-container {
    width: 100%;
  }

  .snapscroll-item > div {
    width: 100% !important;
    margin: 0 !important;
  }
}
