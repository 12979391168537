.cloudinary-container {
  min-height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.cloudinary-container li {
  cursor: pointer;
}

.cloudinary-container textarea {
  height: 500px;
  margin: 20px;
  font-size: 30px;
  padding: 60px;
}
