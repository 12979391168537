.feature-card {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  border-radius: 5px;
  height: 600px;
  width: 400px;
  margin: 10px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}
.feature-image {
  height: 50%;
  width: 100%;
  /*background-image: url(https://smellsandsoundsoflondon.com/wp-content/uploads/2022/12/dining-in-the-skies-2.jpeg);*/
  min-height: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}

.feature-image > span {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 16px;
  font-weight: bold;
  margin: 20px;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
}
.feature-title {
  font-size: 22px;
  padding-top: 20px;
}
.feature-description {
  color: rgb(100, 100, 100);
  padding: 20px;
  text-align: justify;
  height: 200px;
  overflow: hidden;
  margin-bottom: 20px;
}

.feature-footer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.feature-price {
  color: #35979c;
  font-size: 25px;
}

.FaStar {
  color: gold;
}
