.carousel-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.carousel-wrap {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  overflow: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding-left: calc((100vw / 2) - 100px);
  padding-right: calc((100vw / 2) - 180px);
}

.carousel-wrap::-webkit-scrollbar {
  display: none;
}
.slide-test {
  height: 300px;
  width: 200px;
  min-width: 200px;
  background-color: purple;
  margin: 10px;
}

.blue {
  background-color: blue;
}
#slides-arrow-prev {
  position: absolute;
  left: 0;
  height: 100%;
  width: 80px;
  font-size: 60px;
  z-index: 2;
}

#slides-arrow-next {
  position: absolute;
  right: 0;
  height: 100%;
  width: 80px;
  font-size: 60px;
  z-index: 2;
}
