.contact-container {
  min-height: calc(100vh - 310px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contacts-title-container {
  position: absolute;
  top: 100px;
  left: 20px;
}
.contacts-divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  position: relative;
  width: calc(100% - 40px);
  min-height: 100px;
  flex-wrap: wrap;
  padding: 20px;
}
.contacts-divider > div {
  min-height: 300px;
  min-width: 600px;
  position: relative;
}
.contacts-divider > div > div {
  height: 360px;
  width: 560px;
  padding: 20px;
  position: absolute;
  top: -100px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  background-color: white;
}

.contact-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.contact-card p > span {
  display: block;
  text-align: center;
  font-weight: 200;
}
.contact-card svg {
  font-size: 40px;
  color: #404040;
}
.contact-card span {
  font-size: 16px;
  font-weight: bold;
}

.contact-card ul {
  padding: 0;
}
.contact-card ul li {
  list-style-type: none;
  font-weight: bold;
  margin-top: 16px;
  color: #35979c;
}

.contact-card li svg {
  font-size: 20px;
}

.contact-card button {
  background: #35979c;
  background-image: -webkit-linear-gradient(top, #35979c, #1f9fa3);
  background-image: -moz-linear-gradient(top, #35979c, #1f9fa3);
  background-image: -ms-linear-gradient(top, #35979c, #1f9fa3);
  background-image: -o-linear-gradient(top, #35979c, #1f9fa3);
  background-image: linear-gradient(to bottom, #35979c, #1f9fa3);
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 30px 10px 30px;
  text-decoration: none;
  border-style: none;
  cursor: pointer;
}

.contact-card button:hover {
  background: #3dc7cc;
  background-image: -webkit-linear-gradient(top, #3dc7cc, #35979c);
  background-image: -moz-linear-gradient(top, #3dc7cc, #35979c);
  background-image: -ms-linear-gradient(top, #3dc7cc, #35979c);
  background-image: -o-linear-gradient(top, #3dc7cc, #35979c);
  background-image: linear-gradient(to bottom, #3dc7cc, #35979c);
  text-decoration: none;
}

.social-links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  max-width: 1240px;
  width: calc(100% - 40px);
  align-items: center;
  justify-content: center;
}

.social-links > div {
  width: 250px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 10px -1px rgba(222, 222, 222, 0.75);
  -webkit-box-shadow: 0px 1px 10px -1px rgba(222, 222, 222, 0.75);
  -moz-box-shadow: 0px 1px 10px -1px rgba(222, 222, 222, 0.75);
}

.social-card svg {
  font-size: 40px;
  color: #6a6a6a;
}

@media screen and (max-width: 1296px) {
  .contacts-divider {
    gap: 130px;
  }
}
@media screen and (max-width: 660px) {
  .contacts-divider > div,
  .contacts-divider > div > div {
    min-width: 0;
    width: calc((100% - 40px));
  }
}
