.card-container {
  height: 300px;
  border-radius: 10px;
  background-color: lightgray;
  transition: all 500ms;
}
.card-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: var(data-image);
}
.card-info {
  background-color: white;
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 10px;
  padding: 10px;
  opacity: 0.95;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: calc(100% - 40px);
  box-shadow: -8px -5px 10px -2px rgba(209, 206, 206, 0.75);
  -webkit-box-shadow: -8px -5px 10px -2px rgba(209, 206, 206, 0.75);
  -moz-box-shadow: -8px -5px 10px -2px rgba(209, 206, 206, 0.75);
  padding-left: 20px;
}
.card-info h3 {
  text-align: right;
  font-size: 15px;
  width: 100%;
  margin-top: 0;
}
.card-info span {
  font-size: 20px;
  font-weight: bold;
  color: #35979c;
}

.card-footer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
}

.senses * {
  margin-right: 5px;
}

.gold-star {
  color: gold;
}
