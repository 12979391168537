.review-btn-container span {
  margin: 20px;
  display: flex;
  color: #cfcfcf;
  font-weight: bold;
  cursor: pointer;
  background-color: #002c4c;
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  gap: 12px;
}
.review-btn-container span:hover {
  transform: scale(1.2);
}

.review-btn-container span > svg {
  font-size: 25px;
}
.location {
  cursor: pointer;
}
.location svg {
  animation-iteration-count: infinite;
}
.tour-specs {
  height: 270px;
  background-color: #002c4c;
  position: relative;
  padding-left: 50px;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.bannercover {
  background-image: url(../images/bannercover.png);
  background-size: cover;
  background-position: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  opacity: 0.3;
}
.tour-specs > span {
  color: white;
  font-weight: bold;
  font-size: 25px;
  z-index: 2;
}

#booking-frame {
  min-height: 700px;
}

.thumbnail-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  z-index: 2;
}
.thumbnail-container > div {
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.thumbnail-container .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-weight: bold;
}

.thumbnail-container .icon > svg {
  font-size: 36px;
}

.thumbnail-container .icon > span {
  font-size: 12px;
}

.thumbnail-container .description {
  color: white;
  font-weight: bold;
  font-size: 12px;
  margin-top: 20px;
}

.tour-container {
  min-height: calc(100vh - 120px);
  display: flex;
  padding-top: 20px;
  margin-bottom: 30px;
}
.tour-container > div {
  width: 100%;
}
.tour-image-slider {
  width: 420px;
}

.tour-image-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-right: 20px;
  width: 100%;
  margin-bottom: 30px;
}
.origin-widget-container {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 999999;
  height: var(--height);
}
#origin-widget {
  position: absolute;
  top: -118px;
}
.tour-image-slider img {
  cursor: zoom-in;
}

.tour-image-slider .tour-slide {
  height: 400px;
  width: 400px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: contain;
}

.tour-image-slider .slick-next {
  right: 0 !important;
  color: black;
}

.tour-image-slider .slick-prev {
  left: 0 !important;
}

.slick-next,
.slick-prev {
  z-index: 2;
}
.slick-next::before,
.slick-prev::before {
  color: black !important;
}

.tour-image-thumbnail {
  width: 100px;
  height: 400px;
  margin: 10px;
  /*
  display: flex !important;
  justify-content: center;
  */
}
.tour-image-thumbnail .tour-slide {
  height: 60px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: cover;
}

.tour-image-thumbnail .slick-slide {
  border-style: none !important;
  filter: grayscale(1);
}

.tour-image-thumbnail .slick-next {
  bottom: -25px !important;
  z-index: 2;
  top: auto;
  right: auto;
  left: calc(100px / 2 - 10px);

  transform: rotate(90deg);
}

.tour-image-thumbnail .slick-prev {
  top: -25px !important;
  z-index: 2;
  top: auto;
  left: calc((100px / 2) - 10px);
  right: auto;
  transform: rotate(90deg);
}

.tour-image-thumbnail .slick-current {
  filter: grayscale(0) !important;
}

.tour-image-thumbnail .slick-current img {
  box-sizing: border-box;
  border-color: gold;
  border-style: groove;
}

.tour-description-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
  width: calc(100% - 40px);
  max-width: 716px;
}
.tour-title {
  display: none;
}
.tour-title,
.tour-price {
  font-size: 2rem;
  margin: 10px;
  font-weight: bold;
  display: none;
}
.tour-description {
  text-align: justify;
  font-size: 18px;
  max-width: 700px;
  margin: 10px;
  white-space: pre-line;
}

.tour-info-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-bottom-border {
  border-bottom-style: solid;
  border-width: 1px;
  border-color: lightgray;
}
.timeline-container > h2 {
  margin-left: 20px;
}

.tour-highlights {
  margin-top: 30px;
  max-width: 716px;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}
.tour-highlights > h2 {
  /*margin-left: 20px;*/
}
.tour-highlights ul {
  list-style-type: none;
}
.tour-highlights li {
  padding: 10px;
  text-align: justify;
  position: relative;
}

.tour-highlights li::before {
  content: " ";
  display: inline-block;
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: #ef3c3c;
  position: absolute;
  left: -20px;
  top: 50%;
}

.slider-fullscreen {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 999999999;
}

.slider-fullscreen .slick-slide > div {
  display: flex;
  justify-content: center;
}
.slider-fullscreen img {
  width: 100% !important;
  max-width: calc(100vw - 40px) !important;
  height: calc(100vh - 40px) !important;
  padding: 20px;
  cursor: zoom-out !important;
}
.slider-fullscreen .slick-next {
  right: 25px !important;
}
.slider-fullscreen .slick-prev:before,
.slider-fullscreen .slick-next:before {
  font-size: 40px !important;
  opacity: 0.3;
}
.slider-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #918282;
  font-size: 25px;
  color: white;
  padding: 15px;
  font-weight: bold;
  border-radius: 100%;
  display: flex;
  width: 10px;
  height: 10px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  z-index: 200;
}

@media screen and (max-width: 565px) {
  .tour-image-thumbnail {
    display: none !important;
  }
  .tour-image-slider {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 930px) {
  .tour-container {
    flex-wrap: wrap;
  }

  .tour-image-slider {
    /*width: calc(100% - 20px);*/
  }

  .tour-description-container {
    padding-right: 20px;
  }

  .tour-image-container {
    justify-content: center;
    padding-right: 0;
    margin-bottom: 30px;
  }
  #origin-widget {
    position: static;
    top: 0;
  }

  .booking-container {
    position: static !important;
  }
  .origin-widget-container {
    margin-top: 30px;
    height: 100% !important;
  }
}

.confirmation-modal-hidden{
  display: none !important;
}

.confirmation-modal header{
  padding-left: 20px;
  padding-top: 20px;
}
.confirmation-modal header svg{
  font-size: 25px;
  cursor: pointer;
}
.confirmation-modal{
  position: absolute;
  z-index: 9999999;
  top: 0;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 700px) {
  .tour-specs {
    padding-left: 0;
    text-align: center;
  }

  .tour-specs > span {
    font-size: 20px;
  }

  .thumbnail-container > div {
    margin-right: 0;
  }

  .thumbnail-container {
    justify-content: space-around;
  }
}
@media screen and (min-width: 930px) {
  .origin-widget-container {
    position: sticky;
    height: var(--height);
    top: 130px;
    align-self: flex-start;
  }
}

@media screen and (max-width: 1040px) {
  /*
  #origin-widget {
    position: static;
    top: 0;
  }

  .origin-widget-container {
    margin-top: 30px;
  }
*/
  .tour-info-column {
    align-items: center;
  }

  .tour-image-container {
    margin-bottom: 30px;
  }
}
