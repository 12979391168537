.timeline {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  /*margin: 5% auto;*/
  margin-left: 6rem;
  margin-right: 20px;
}
.timeline__event {
  background: #fff;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}
.timeline__event__title {
  font-size: 16px;
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: 600;
  color: #9251ac;
  letter-spacing: 1.5px;
}
.timeline__event__content {
  padding: 10px;
  font-size: 14px;
  flex: 2;
}
.timeline__event__date {
  color: #f6a4ec;
  font-size: 14px;
  font-weight: 600;
  white-space: pre-wrap;
}
.timeline__event__icon {
  border-radius: 8px 0 0 8px;
  background: #9251ac;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 40%;
  font-size: 20px;
  color: #9251ac;
  padding: 20px;
  flex: 1;
}
.timeline__event__icon i {
  position: absolute;
  top: 50%;
  left: -65px;
  font-size: 20px;
  transform: translateY(-50%);
}
.timeline__event__description {
  flex-basis: 60%;
}

.timeline__event__description p{
  white-space: pre-line;
}

.tour-option .timeline__event__title{
  text-align: center;
  color: rgb(90, 90, 90);
}

.timeline__event:after {
  content: "";
  width: 2px;
  height: var(--dataHeight);
  background: #9251ac;
  position: absolute;
  top: var(--dataTop);
  left: -3.5rem;
  z-index: -1;
}

.timeline__event:before {
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  background: #f6a4ec;
  border-radius: 100%;
  left: -72px;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #9251ac;
}
.timeline__event--type2:before {
  background: #87bbfe;
  border-color: #555ac0;
}
.timeline__event--type2:after {
  background: #555ac0;
}
.timeline__event--type2 .timeline__event__date {
  color: #87bbfe;
}
.timeline__event--type2 .timeline__event__icon {
  background: #555ac0;
  color: #555ac0;
}
.timeline__event--type2 .timeline__event__title {
  color: #555ac0;
}
.timeline__event--type3:before {
  background: #aff1b6;
  border-color: #24b47e;
}
.timeline__event--type3:after {
  background: #24b47e;
}
.timeline__event--type3 .timeline__event__date {
  color: #aff1b6;
}
.timeline__event--type3 .timeline__event__icon {
  background: #24b47e;
  color: #24b47e;
}
.timeline__event--type3 .timeline__event__title {
  color: #24b47e;
}
.timeline__event:last-child:after {
  content: none;
}

.tour-options-container {
  width: 100%;
  max-width: 716px;
}
.tour-options-container > h2 {
  margin-left: 20px;
}
.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tour-option {
  overflow: hidden;
  width: 100%;
  max-width: calc(100% - 20px);
  cursor: pointer;
  background: #fff;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25),
    0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}
.chosen > * {
  background-color: rgb(214, 214, 172) !important;
}
.option-icon {
  background-color: white;
}
.option-text {
  color: #676667 !important;
  
}

.tour-option .timeline__event__description p{
  text-align: justify;
}
.tour-option .timeline__event__description{
  display: flex;
  justify-content: center;
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
  }
  .tour-option{
    flex-direction: column;
  }
  .timeline__event__icon {
    border-radius: 4px 4px 0 0;
  }
}
